.component-modal {
  position: fixed;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
  overflow: auto;

  &.size-full {
    &>.wrapper {
      width: calc(100vw - 40px);
      max-width: none;
      min-height: calc(100vh - 40px);
    }
  }

  &.not-closable {
    cursor: auto;
  }

  &>.wrapper {
    margin: 20px;
    width: 700px;
    max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;
    border-radius: 8px;
    background: white;
    height: fit-content;
    cursor: auto;
    display: flex;

    .list-page {
      width: 100%;
    }


    .head-component {
      border-radius: 8px 8px 0px 0px;
    }
  }

  // &>.wrapper {
  //   margin: 20px;
  //   width: 700px;
  //   max-width: 100%;
  //   box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;
  //   border-radius: 6px;
  //   background: white;
  //   min-height: 400px;
  //   height: fit-content;
  //   cursor: auto;
  //   display: flex;

  //   .head {
  //     background: #E9E9E9;
  //     font-weight: bold;
  //     padding: 20px;
  //     font-size: 1.2em;
  //     border-radius: 6px 6px 0px 0px;

  //     .close {
  //       border-radius: 3px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       padding: 10px;
  //       cursor: pointer;

  //       svg {
  //         width: 25px;
  //         height: 25px;
  //       }

  //       &:hover {
  //         background-color: white;
  //       }
  //     }
  //   }
  // }
}