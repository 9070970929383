form input::-webkit-outer-spin-button,
form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
form .head {
  flex: 0 0 auto;
}
form .choose-type {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
form .choose-type .wrapper {
  padding: 20px;
  display: flex;
}
form .choose-type .wrapper > * + * {
  margin-left: 20px;
}
form .choose-type .wrapper .type {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 250px;
  width: 250px;
}
form .choose-type .wrapper .type > div {
  text-align: center;
}
form .choose-type .wrapper .type > div .name {
  margin-top: 5px;
  font-size: 1.2em;
  font-weight: bold;
}
form .choose-type .wrapper .type:hover {
  background-color: #ceddff;
  color: #3e72df;
  border-color: blue;
}
form .fieldset {
  margin: 0px 20px;
  padding: 20px 0px;
  border-bottom: 1px solid #f4f4f4;
}
form .fieldset.no-border {
  border: none;
}
form hr {
  border: none;
  height: 1px;
  width: 100%;
  background: #ccc;
}
form .error-message {
  color: red;
  padding: 4px;
}
form .input-map {
  border-radius: 8px;
  border: 1px solid #ccc;
}
form .date-range-picker {
  position: relative;
}
form .date-range-picker .value {
  background: white;
  display: block;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  font-size: 1em;
  outline: none;
  cursor: pointer;
}
form .date-range-picker .dialog {
  z-index: 999;
  position: absolute;
  display: flex;
  top: 100%;
  right: 0px;
  width: 850px;
  background: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 16px 0px;
}
form .date-range-picker .dialog .shortcuts {
  width: 150px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}
form .date-range-picker .dialog .shortcuts .shortcut {
  background: none;
  text-align: left;
  width: 100%;
  color: blue;
}
form .date-range-picker .dialog .shortcuts .shortcut:hover {
  background: #E9E9E9;
}
form .date-range-picker .dialog .shortcuts .separator {
  background: #D9D9D9;
  height: 1px;
  margin: 10px 0px;
}
form .date-range-picker .dialog .shortcuts .extensible {
  flex: 1;
}
form .date-range-picker .dialog .goTo {
  padding: 0px 5px;
  padding-bottom: 0px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
}
form .date-range-picker .dialog .goTo svg {
  width: 15px;
}
form .date-range-picker .dialog .goTo:hover {
  background: #E9E9E9;
}
form .date-range-picker .dialog .goTo.up {
  margin-top: 23px;
}
form .date-range-picker .dialog .goTo.up svg {
  transform: rotate(-90deg);
}
form .date-range-picker .dialog .goTo.down {
  margin-top: 23px;
}
form .date-range-picker .dialog .goTo.down svg {
  transform: rotate(-90deg);
}
form .date-range-picker .dialog .months {
  flex: 1;
  display: flex;
  margin: 0px 5px;
}
form .date-range-picker .dialog .months > * + * {
  margin-left: 5px;
}
form .date-range-picker .dialog .months .week {
  display: flex;
}
form .date-range-picker .dialog .months .week .day {
  flex: 1;
  text-align: center;
  padding: 10px 0px;
  background: none;
  cursor: pointer;
}
form .date-range-picker .dialog .months .week .day.selected {
  background: #93c1fd;
  margin: 2px 0px;
  padding: 8px 0px;
}
form .date-range-picker .dialog .months .week .day.selected.first {
  border-radius: 3px 0px 0px 3px;
  background: #2881f3;
  color: white;
}
form .date-range-picker .dialog .months .week .day.selected.last {
  border-radius: 0px 3px 3px 0px;
  background: #2881f3;
  color: white;
}
form .date-range-picker .dialog .months .week .day.selected.first.last {
  border-radius: 3px;
}
form .date-range-picker .dialog .months .week .day.disabled {
  cursor: not-allowed;
  background: #D9D9D9;
}
form .input-radio {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
form .input-radio.ghost {
  cursor: inherit;
}
form .input-radio.ghost:hover {
  background: none;
}
form .input-radio.align-center {
  align-items: center;
}
form .input-radio.input-radio .input {
  border-radius: 20px;
}
form .input-radio.inline {
  width: fit-content;
}
form .input-radio.inline .input {
  margin: 0px;
}
form .input-radio.disabled .input {
  background: #E9E9E9;
  border-color: #E9E9E9;
  cursor: not-allowed;
}
form .input-radio.disabled .label {
  color: grey;
}
form .input-radio.disabled:hover {
  background: none;
  cursor: not-allowed;
}
form .input-radio.switchable .input {
  border-radius: 20px;
  width: 40px;
  position: relative;
  transition-duration: 0.1s;
}
form .input-radio.switchable .input::after {
  content: " ";
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  background: #ccc;
  transition-duration: 0.1s;
}
form .input-radio.switchable .input.checked {
  justify-content: flex-start;
}
form .input-radio.switchable .input.checked svg {
  margin-left: 4px;
}
form .input-radio.switchable .input.checked::after {
  left: 22px;
  background: green;
}
form .input-radio:not(.inline):not(.ghost):hover {
  border-radius: 8px;
  background-color: #E9E9E9;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-left: -5px;
  padding: 5px 0px;
  padding-left: 5px;
}
form .input-radio .label {
  margin-right: 10px;
  width: 100%;
}
form .input-radio .input {
  width: auto;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  flex: 0 0 auto;
  margin: 0px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: white;
}
form .input-radio .input.checked {
  border-color: #520b4e;
  background-color: #520b4e;
  display: flex;
  align-items: center;
  justify-content: center;
}
form .input-radio .input.checked svg {
  width: 10px;
  height: 10px;
  fill: #a8ffa8;
}
form .image-input {
  display: flex;
  align-items: center;
}
form .image-input input {
  display: none;
}
form .image-input .image {
  cursor: pointer;
  -webkit-appearance: none;
  display: block;
  border: 1px solid #ccc;
  padding: 2px;
  border-radius: 8px;
  width: 200px;
  height: 200px;
  font-size: 1em;
  outline: none;
  margin-right: 20px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #F9F9F9;
}
form .image-input .image .description-over {
  display: none;
  width: 100%;
  padding: 5px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
}
form .image-input .image button {
  display: none;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  color: #3e72df;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px;
  border-radius: 0px 0px 7px 7px;
  outline: none;
  background-color: white;
}
form .image-input .image:hover button, form .image-input .image:hover .description-over {
  display: block;
}
form .sub {
  display: flex;
}
form .sub > * + * {
  margin-left: 20px;
}
form .sub .part {
  flex: 1;
}
form .sub .part.part-2 {
  flex: 2;
}
form .sub .part.part-3 {
  flex: 3;
}
form .sub .part-break {
  display: flex;
  align-items: flex-end;
  padding-bottom: 14px;
}
form .title {
  font-weight: bold;
}
form label {
  display: flex;
  margin-bottom: 4px;
}
form input:not(#openLinkInNewWindow), form select, form textarea {
  background: white;
  -webkit-appearance: none;
  display: block;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  font-size: 1em;
  outline: none;
}
form input:not(#openLinkInNewWindow)[type=date], form select[type=date], form textarea[type=date] {
  padding: 9px 10px;
}
form input:not(#openLinkInNewWindow):disabled, form select:disabled, form textarea:disabled {
  cursor: not-allowed;
  background-color: #E9E9E9;
}
form select {
  cursor: pointer;
}
form textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 117px;
  resize: none;
  max-height: 600px;
}