.html-component .editor {
  border-radius: 8px;
  border: 1px solid #ccc;
}
.html-component .editor .editorClassName {
  padding: 0px 20px;
}
.html-component .editor .rdw-editor-toolbar.toolbarClassName {
  border-radius: 8px 8px 0px 0px;
}
.html-component .editor .rdw-editor-toolbar {
  border-top: none;
  border-right: none;
  border-left: none;
}
.html-component .editor .rdw-link-modal {
  height: auto;
}
.html-component .label {
  display: flex;
  justify-content: space-between;
}
.html-component .label .text {
  flex: 1;
}
.html-component .label .tabs {
  display: flex;
  padding-right: 20px;
}
.html-component .label .tabs > * + * {
  margin-left: 20px;
}
.html-component .label .tabs .tab {
  cursor: pointer;
  padding: 2px 5px;
  font-size: 0.9em;
}
.html-component .label .tabs .tab.active {
  padding-bottom: 1px;
  margin-bottom: -1px;
  border-bottom: 2px solid blue;
  color: blue;
}