.checkbox-component {
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  &.ghost {
    cursor: inherit;

    &:hover {
      background: none;
    }
  }

  &.align-center {
    align-items: center;
  }

  &.no-label {
    width: fit-content;

    .input {
      margin: 0px;
    }
  }

  &.inline {
    width: fit-content;

    .input {
      margin: 0px;
    }
  }

  &.disabled {
    .input {
      background: #E9E9E9;
      border-color: #E9E9E9;
      cursor: not-allowed;
    }

    .label {
      color: grey;
    }

    &:hover {
      background: none;
      cursor: not-allowed;
    }
  }

  &.switchable {
    .input {
      border-radius: 20px;
      width: 40px;
      position: relative;
      transition-duration: 0.1s;

      &::after {
        content: ' ';
        position: absolute;
        top: 2px;
        left: 2px;
        border-radius: 18px;
        width: 14px;
        height: 14px;
        background: #ccc;
        transition-duration: 0.1s;
      }

      &.checked {
        justify-content: flex-start;

        svg {
          margin-left: 4px;
        }

        &::after {
          left: 22px;
          background: green;
        }
      }
    }
  }

  &:not(.inline):not(.ghost):hover {
    border-radius: 8px;
    background-color: #E9E9E9;
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: -5px;
    padding: 5px 0px;
    padding-left: 5px;
  }

  .label {
    margin-right: 10px;
    width: 100%;
  }

  .input {
    width: auto;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    flex: 0 0 auto;
    margin: 0px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background: white;

    &.checked {
      border-color: #520b4e;
      background-color: #520b4e;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 10px;
        height: 10px;
        fill: #a8ffa8;
      }
    }
  }
}