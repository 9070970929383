.tabs-component {
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  border-bottom: 1px solid #eaeaea;
  padding-top: 5px;
  z-index: 999;
}
.tabs-component.sub {
  background: #F9F9F9;
}
.tabs-component.no-padding {
  padding: 0px;
}
.tabs-component > * + * {
  margin-left: 20px;
}
.tabs-component .tab-component {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  cursor: pointer;
  border-radius: 4px 4px 0px 0px;
  color: grey;
  white-space: nowrap;
}
.tabs-component .tab-component:hover {
  color: #007bff;
}
.tabs-component .tab-component.active {
  position: relative;
  color: #007bff;
}
.tabs-component .tab-component.active::after {
  content: " ";
  position: absolute;
  background: #007bff;
  height: 3px;
  bottom: -2px;
  left: 0px;
  width: 100%;
  border-radius: 3px;
}

@media screen and (max-width: 650px) {
  .tabs {
    max-width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    border: none;
  }
}