.select-component {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  background: white;
  user-select: none;

  &.inline {
    &>.value {

      .item {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }

  input {
    border-bottom: 0px;
    border-radius: 0px;
  }

  .grid {
    z-index: 90;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(100, 100, 100, 0.4);
  }

  .value {
    display: flex;
    align-items: center;
    cursor: pointer;

    .item {
      flex: 1;
      padding: 10px;
      white-space: nowrap;
    }

    .unselect {
      padding: 0px 5px;
      cursor: pointer;
      margin-left: 5px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      margin: 5px !important;
      padding: 5px;

      &:hover {
        background: #E9E9E9;
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }

    .icon {
      padding: 0px 5px;
      cursor: pointer;
      margin-left: 5px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      margin: 5px !important;
      padding: 5px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .popin {
    z-index: 999;
    position: absolute;
    top: 0%;
    left: 0px;
    width: calc(100% + 2px);
    background: white;
    border-radius: 8px;
    border: 1px solid white;
    cursor: auto;
    margin: 0px -1px;
    margin-top: -1px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 16px 0px;
    overflow: hidden;

    input {
      border-left: none;
      border-right: none;
      border-radius: 0px;
    }

    .loading, .no-item {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .data {
      max-height: 300px;
      overflow: auto;
      border-top: 1px solid #ccc;

      &>div {
        cursor: pointer;
        padding: 10px;

        &:hover {
          background: #F9F9F9;
        }
      }

      .value {

        &.selected {
          color: white;
          background: #3e72df;
          display: flex;
          justify-content: space-between;

          .unselect {
            background: white;
            margin: -10px !important;
            margin-right: -3px !important;
            svg {
              fill: blue;
            }
          }

          &:hover {
            background: blue;
          }
        }

        .item {
          padding: 0px;

        }
      }
    }
  }
}