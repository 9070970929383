.switch-component {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  background: white;
  user-select: none;
  display: flex;

  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    display: flex;
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    font-size: 1em;
    outline: none;
    cursor: pointer;
    margin: -1px;
    margin-right: 0px;
    white-space: nowrap;
    text-align: center;
    justify-content: center;

    &.active {
      background-color: #ceddff;
      color: #3e72df;
      border-color: blue;
      z-index: 1;
    }

    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }

    &:last-child {
      border-radius: 0px 8px 8px 0px;
      margin-right: -1px;
    }
  }
}