form.login {
  width: 100%;
  max-width: 350px;
  margin: 0px;

  img {
    margin: 20px 0px;
  }

  input {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    margin-bottom: 10px;
    font-size: 1.2em;
    outline: none;

    &:focus {
      border-color: #5dd0f3;
    }
  }

  button, a.btn {
    text-align: center;
    border: none;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    margin-bottom: 10px;
    font-size: 1.2em;
    cursor: pointer;
    background: #520b4e;
    color: white;

    &:hover {
      background-color: #863781;
    }
  }

  a {
    text-decoration: none;
    display: block;
    text-align: center;
    cursor: pointer;
    background: white;
    border-radius: 3px;
    padding: 10px 0px;
    font-size: 0.9em;

    &.forgot-password {
      text-align: right;
      padding: 0px;
      padding-right: 5px;
      margin-bottom: 20px;
      margin-top: -5px;

      &:hover {
        background: none;
      }
    }

    &:hover {
      background: #daf7ff;
    }
  }
}