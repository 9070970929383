.user-layout {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.is-test {
    &>.wrapper {

      .corp {

        .menu {
          max-height: calc(100vh - 51px);
        }

        .page {
          max-height: calc(100vh - 51px);
        }
      }
    }
  }

  .ressource-page {
    flex: 1;
    display: flex;
    flex-direction: column;

    &>.loader-component {
      padding: 20px 0px;
    }
  
    .content {
      padding: 20px;
  
      &.small {
        max-width: 770px;
      }
  
      .field {
        display: flex;
        margin-bottom: 20px;
  
        &.box {
          .key {
            margin-top: 0px;
          }
        }
  
        .key {
          width: 200px;
          font-weight: bold;
          text-align: right;
          margin-right: 20px;
          margin-top: 10px;
        }
  
        .value {
          flex: 1;
          
          & > * + * {
            margin-top: 10px;
          }
  
          .line {
            display: flex;
  
            & > * + * {
              margin-left: 20px;
            }
            & > * {
              flex: 1;
            }
          }
        }
      }
    }
  }

  &>.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    transform: translate(0px, 0px);
    transition-duration: 500ms;
    background: white;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.3) 6px 6px 16px 0px;
    width: 100%;

    .corp {
      flex: 1;
      display: flex;

      .menu {
        font-size: 1.2em;
        display: flex;
        flex-direction: column;
        width: 170px;
        border-right: 1px solid #e3e9ed;
        box-shadow: 0px 2px 0px #D9D9D9;
        z-index: 10;
        max-height: 100vh;
        overflow: auto;
        transition-duration: 300ms;
        background-color: #F9F9F9;

        .logo {
          margin-bottom: 16px;
          margin-top: 5px;

          svg {
            color: #da4830;
          }
        }

        &.mobile {
          display: none;
        }

        .category {
          text-transform: uppercase;
          font-size: 0.6em;
          color: grey;
          font-weight: 700;
          padding: 10px 15px;
          margin-top: 16px;
        }

        a {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 0.8em;
          margin: 0px 6px;
          padding: 10px 9px;
          cursor: pointer;
          font-weight: 500;
          border-radius: 6px;

          &:hover {
            background-color: white;
          }

          &.disabled {
            color: grey;
            cursor: not-allowed;
          }

          &.sub-link {
            padding-left: 25px;
            font-weight: normal;
          }

          &.active {
            background-color: white;
            border: 1px solid #e3e9ed;
            padding: 9px 8px;
          }
        }

        &>.sub {
          position: relative;
          display: flex;
          justify-content: center;
          cursor: pointer;

          .line {
            z-index: 2;
            position: absolute;
            height: 10px;
            top: 50%;
            margin-top: -6px;
            background: #dfe5eb;
            width: 100%;
          }

          .text {
            z-index: 3;
            position: relative;
            background: white;
            color: #a4a6a9;
            font-size: 0.7em;
            margin: auto;
            padding: 8px 5px;
            text-transform: uppercase;
            margin-bottom: 3px;
          }
        }

        .separator {
          flex: 1;
          margin: 10px 0px;
        }

        .account {
          z-index: 999;
          position: relative;
          border: none;
          border-top: 1px solid #eaeaea;
          font-size: 1em;
          background: none;
          text-align: left;
          cursor: pointer;
          font-size: 0.8em;
          border-radius: 0px;
          cursor: pointer;
          padding: 16px;
          cursor: pointer;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 8px;
          color: #007bff;

          &:hover {
            background-color: #F9F9F9;
          }

          .sub {
            z-index: 999;
            position: absolute;
            left: 0px;
            bottom: 100%;
            background: white;
            padding: 4px 0px;
            border: 1px solid #ccc;
            width: 100%;
            color: black;
            border-radius: 4px;
            display: flex;

            button {
              z-index: 999;
              position: relative;
              border: none;
              background: none;
              text-align: left;
              cursor: pointer;
              font-size: 1em;
              border-radius: 0px;
              cursor: pointer;
              padding: 16px;
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 8px;
              color: #007bff;
              width: 100%;

              &:hover {
                background-color: #F9F9F9;
              }
            }
          }
        }
      }

      .page {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: white;
        max-height: 100vh;
        overflow: auto;
        transition-duration: 300ms;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .user-layout {
    .wrapper {
      flex-direction: column-reverse;
      max-height: 100vh;
      position: relative;
    }
  }
}

@media print {
  body {
    min-height: none;
    max-height: none;

    .user-layout {
      min-height: none;
      max-height: none;
  
      .wrapper {
        flex-direction: column-reverse;
        position: relative;
        min-height: none;
        max-height: none;
  
        .corp {
    
          .menu {
            display: none;
          }
  
          .page {
            min-height: none;
            max-height: none;
          }
        }
      }
    }
  }
  
}