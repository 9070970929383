.select-component {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  background: white;
  user-select: none;
}
.select-component.inline > .value .item {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
.select-component input {
  border-bottom: 0px;
  border-radius: 0px;
}
.select-component .grid {
  z-index: 90;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(100, 100, 100, 0.4);
}
.select-component .value {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.select-component .value .item {
  flex: 1;
  padding: 10px;
  white-space: nowrap;
}
.select-component .value .unselect {
  padding: 0px 5px;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: 5px !important;
  padding: 5px;
}
.select-component .value .unselect:hover {
  background: #E9E9E9;
}
.select-component .value .unselect svg {
  width: 15px;
  height: 15px;
}
.select-component .value .icon {
  padding: 0px 5px;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: 5px !important;
  padding: 5px;
}
.select-component .value .icon svg {
  width: 12px;
  height: 12px;
}
.select-component .popin {
  z-index: 999;
  position: absolute;
  top: 0%;
  left: 0px;
  width: calc(100% + 2px);
  background: white;
  border-radius: 8px;
  border: 1px solid white;
  cursor: auto;
  margin: 0px -1px;
  margin-top: -1px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 16px 0px;
  overflow: hidden;
}
.select-component .popin input {
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.select-component .popin .loading, .select-component .popin .no-item {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-component .popin .data {
  max-height: 300px;
  overflow: auto;
  border-top: 1px solid #ccc;
}
.select-component .popin .data > div {
  cursor: pointer;
  padding: 10px;
}
.select-component .popin .data > div:hover {
  background: #F9F9F9;
}
.select-component .popin .data .value.selected {
  color: white;
  background: #3e72df;
  display: flex;
  justify-content: space-between;
}
.select-component .popin .data .value.selected .unselect {
  background: white;
  margin: -10px !important;
  margin-right: -3px !important;
}
.select-component .popin .data .value.selected .unselect svg {
  fill: blue;
}
.select-component .popin .data .value.selected:hover {
  background: blue;
}
.select-component .popin .data .value .item {
  padding: 0px;
}