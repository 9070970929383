.registration-add-classe-modal {
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;

  .filters {
    margin-bottom: 0px;
    display: flex;

    &> * + * {
      margin-left: 20px;
    }

    .filter {
      
      &.full {
        flex: 1;
      }
    }
  }

  .choose-list {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .classes {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-auto-columns: minmax(100px, auto);
      grid-gap: 20px;

      .classe {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background: #F9F9F9;
        }

        &.selected {
          background: #93c1fd;
          border-color: blue;
        }
      }
    }
  }
}