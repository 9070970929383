.registration-add-classe-modal {
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
.registration-add-classe-modal .filters {
  margin-bottom: 0px;
  display: flex;
}
.registration-add-classe-modal .filters > * + * {
  margin-left: 20px;
}
.registration-add-classe-modal .filters .filter.full {
  flex: 1;
}
.registration-add-classe-modal .choose-list {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.registration-add-classe-modal .choose-list .classes {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-columns: minmax(100px, auto);
  grid-gap: 20px;
}
.registration-add-classe-modal .choose-list .classes .classe {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
}
.registration-add-classe-modal .choose-list .classes .classe:hover {
  background: #F9F9F9;
}
.registration-add-classe-modal .choose-list .classes .classe.selected {
  background: #93c1fd;
  border-color: blue;
}