.component-modal {
  position: fixed;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
  overflow: auto;
}
.component-modal.size-full > .wrapper {
  width: calc(100vw - 40px);
  max-width: none;
  min-height: calc(100vh - 40px);
}
.component-modal.not-closable {
  cursor: auto;
}
.component-modal > .wrapper {
  margin: 20px;
  width: 700px;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;
  border-radius: 8px;
  background: white;
  height: fit-content;
  cursor: auto;
  display: flex;
}
.component-modal > .wrapper .list-page {
  width: 100%;
}
.component-modal > .wrapper .head-component {
  border-radius: 8px 8px 0px 0px;
}