form {
  // display: flex;
  // flex-direction: column;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  .head {
    flex: 0 0 auto;
  }

  .choose-type {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .wrapper {
      padding: 20px;
      display: flex;

      &> * + * {
        margin-left: 20px;
      }

      .type {
        flex: 1;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 250px;
        width: 250px;

        &>div {
          text-align: center;

          .name {
            margin-top: 5px;
            font-size: 1.2em;
            font-weight: bold;
          }
        }

        &:hover {
          background-color: #ceddff;
          color: #3e72df;
          border-color: blue;
        }
      }
    }
  }

  .fieldset {
    margin: 0px 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #f4f4f4;

    &.no-border {
      border: none;
    }
  }

  hr {
    border: none;
    height: 1px;
    width: 100%;
    background: #ccc;
  }

  .error-message {
    color: red;
    padding: 4px;
  }

  .input-map {
    border-radius: 8px;
    border: 1px solid #ccc;
  }

  .date-range-picker {
    position: relative;

    .value {
      background: white;
      display: block;
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 8px;
      width: 100%;
      font-size: 1em;
      outline: none;
      cursor: pointer;
    }

    .dialog {
      z-index: 999;
      position: absolute;
      display: flex;
      top: 100%;
      right: 0px;
      width: 850px;
      background: white;
      border-radius: 8px;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 16px 0px;
    
      .shortcuts {
        width: 150px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
    
        .shortcut {
          background: none;
          text-align: left;
          width: 100%;
          color: blue;
    
          &:hover {
            background: #E9E9E9;
          }
        }
    
        .separator {
          background: #D9D9D9;
          height: 1px;
          margin: 10px 0px;
        }
    
        .extensible {
          flex: 1;
        }
      }
    
      .goTo {
        padding: 0px 5px;
        padding-bottom: 0px;
        border-radius: 3px;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
    
        svg {
          width: 15px;
        }
    
        &:hover {
          background: #E9E9E9;
        }
    
        &.up {
          margin-top: 23px;

          svg {
            transform: rotate(-90deg);
          }
        }
    
        &.down {
          margin-top: 23px;

          svg {
            transform: rotate(-90deg);
          }
        }
      }

      .months {
        flex: 1;
        display: flex;
        margin: 0px 5px;

        &> * + * {
          margin-left: 5px;
        }

        .week {
          display: flex;
          
          .day {
            flex: 1;
            text-align: center;
            padding: 10px 0px;
            background: none;
            cursor: pointer;
      
            &.selected {
              background: #93c1fd;
              margin: 2px 0px;
              padding: 8px 0px;
      
              &.first {
                border-radius: 3px 0px 0px 3px;
                background: #2881f3;
                color: white;
              }
        
              &.last {
                border-radius: 0px 3px 3px 0px;
                background: #2881f3;
                color: white;
              }
      
              &.first.last {
                border-radius: 3px;
              }
            }
      
            &.disabled {
              cursor: not-allowed;
              background: #D9D9D9;
            }
          }
        }
      }      
    }
  }

  .input-radio {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    &.ghost {
      cursor: inherit;

      &:hover {
        background: none;
      }
    }

    &.align-center {
      align-items: center;
    }

    &.input-radio {
      .input {
        border-radius: 20px;
      }
    }

    &.inline {
      width: fit-content;

      .input {
        margin: 0px;
      }
    }

    &.disabled {
      .input {
        background: #E9E9E9;
        border-color: #E9E9E9;
        cursor: not-allowed;
      }

      .label {
        color: grey;
      }

      &:hover {
        background: none;
        cursor: not-allowed;
      }
    }

    &.switchable {
      .input {
        border-radius: 20px;
        width: 40px;
        position: relative;
        transition-duration: 0.1s;

        &::after {
          content: ' ';
          position: absolute;
          top: 2px;
          left: 2px;
          border-radius: 18px;
          width: 14px;
          height: 14px;
          background: #ccc;
          transition-duration: 0.1s;
        }

        &.checked {
          justify-content: flex-start;

          svg {
            margin-left: 4px;
          }

          &::after {
            left: 22px;
            background: green;
          }
        }
      }
    }

    &:not(.inline):not(.ghost):hover {
      border-radius: 8px;
      background-color: #E9E9E9;
      margin-top: -5px;
      margin-bottom: -5px;
      margin-left: -5px;
      padding: 5px 0px;
      padding-left: 5px;
    }

    .label {
      margin-right: 10px;
      width: 100%;
    }

    .input {
      width: auto;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 8px;
      flex: 0 0 auto;
      margin: 0px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background: white;

      &.checked {
        border-color: #520b4e;
        background-color: #520b4e;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 10px;
          height: 10px;
          fill: #a8ffa8;
        }
      }
    }
  }

  .image-input {
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    .image {
      cursor: pointer;
      -webkit-appearance: none;
      display: block;
      border: 1px solid #ccc;
      padding: 2px;
      border-radius: 8px;
      width: 200px;
      height: 200px;
      font-size: 1em;
      outline: none;
      margin-right: 20px;
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #F9F9F9;

      .description-over {
        display: none;
        width: 100%;
        padding: 5px;
        background: rgba(255,255,255,0.4);
        border-radius: 4px;
      }

      button {
        display: none;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        color: #3e72df;
        font-weight: bold;
        font-size: 1em;
        cursor: pointer;
        background: none;
        border: none;
        padding: 10px;
        border-radius: 0px 0px 7px 7px;
        outline: none;
        background-color: white;
      }

      &:hover {

        button, .description-over {
          display: block;
        }
      }
    }

    .description {

    }
  }

  .sub {
    display: flex;

    &> * + * {
      margin-left: 20px;
    }

    .part {
      flex: 1;

      &.part-2 {
        flex: 2;
      }

      &.part-3 {
        flex: 3;
      }
    }

    .part-break {
      display: flex;
      align-items: flex-end;
      padding-bottom: 14px;
    }
  }

  .title {
    font-weight: bold;
  }

  label {
    display: flex;
    margin-bottom: 4px;
  }
  input:not(#openLinkInNewWindow), select, textarea {
    background: white;
    -webkit-appearance: none;
    display: block;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    font-size: 1em;
    outline: none;

    &[type=date] {
      padding: 9px 10px; // Fix chrome
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #E9E9E9;
    }
  }
  select {
    cursor: pointer;
  }
  textarea {
    max-width: 100%;
    min-width: 100%;
    min-height: 117px;
    resize: none;
    max-height: 600px;
  }
}