.dashboard-page {
  padding: 20px;
}
.dashboard-page h1 {
  margin-top: 0px;
}
.dashboard-page .numbers {
  display: flex;
}
.dashboard-page .split {
  display: flex;
}
.dashboard-page .split > * + * {
  margin-left: 20px;
}
.dashboard-page .panel {
  flex: 1;
}
.dashboard-page .panel h2 {
  margin-top: 0px;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 8px;
  margin-bottom: 0px;
}
.dashboard-page .panel .btn {
  border-top: 1px solid #D9D9D9;
  padding-top: 10px;
  width: 100%;
}

#graph-wrapper .line {
  fill: url(#temperature-gradient);
  stroke: #fdd002;
  stroke-width: 2px;
}

@media screen and (max-width: 650px) {
  .dashboard-page .numbers {
    flex-direction: column;
  }
}