@-webkit-keyframes rotating-component /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating-component {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader-component {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-component svg {
  width: 28px;
  height: 28px;
  -webkit-animation: rotating-component 1s linear infinite;
  -moz-animation: rotating-component 1s linear infinite;
  -ms-animation: rotating-component 1s linear infinite;
  -o-animation: rotating-component 1s linear infinite;
  animation: rotating-component 1s linear infinite;
}