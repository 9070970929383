.list-component {
  display: flex;
  flex: 1;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    width: 100%;

    .search {
      border-bottom: 1px solid #f4f4f4;
      border-top: 1px solid #f4f4f4;

      input {
        border: none;
        padding: 10px 20px;
        font-size: 1em;
        background: white;
        outline: none;
        width: 100%;
      }
    }

    .wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;

      .header {
        position: sticky;
        background: white;
        top: 0px;
        display: flex;
        border-bottom: 1px solid #f4f4f4;
        padding: 0px 15px;
        z-index: 99;

        .prop {
          flex: 1;
          color: #b6b6b6;
          padding: 20px 5px;
          cursor: pointer;

          &.select {
            width: 30px;
            flex: 0 0 auto;
          }
        }

        .checkbox {
          padding: 0px 5px;
          flex: 0 0 auto;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .no-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ccc;
        font-size: 2em;
      }

      .loader-component {
        padding: 20px 0px;
      }

      .list {

        &.grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-auto-columns: minmax(100px, auto);
          grid-gap: 20px;
        }

        &>.item {
          display: flex;
          align-items: center;
          padding: 15px 15px;
          cursor: pointer;
          color: black;
          position: relative;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */

          .prop {
            padding: 0px 5px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.select {
              width: 30px;
              flex: 0 0 auto;
            }
          }

          .checkbox {
            padding: 0px 5px;
            flex: 0 0 auto;
            white-space: nowrap;
            overflow: hidden;
          }

          &:hover {
            background-color: #F9F9F9;
          }

          &.selected {
            background: #0099ff;
            color: white;

            * {
              color: white !important;
            }
          }

          .context-menu {
            z-index: 9;
            min-width: 250px;
            position: absolute;
            background: white;
            overflow: hidden;
            border-radius: 4px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;

            &> * + * {
              border-top: 1px solid #efefef;
            }

            .action {
              padding: 10px;
              padding-left: 20px;
              cursor: pointer;
              display: flex;
              align-items: center;

              &.disabled {
                background: #F9F9F9;
                cursor: not-allowed;

                svg {
                  fill: grey;
                }
                
                .text {
                  color: grey;
                }
              }

              &.danger {

                svg {
                  fill: red;
                }

                .text {
                  color: red;
                }
              }

              &:hover {
                background-color: #F9F9F9;
              }

              svg {
                width: 22px;
                height: 22px;
                margin-right: 15px;
              }

              .text {
                flex: 1;
                color: black;
              }
            }
          }
        }
      }
    }
  }
}
