.guest-layout {
  display: flex;
  flex: 1;
  background: white;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .wrapper {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    footer {
      padding: 20px;
      text-align: center;
      color: grey;
      font-size: 0.9em;
    }
  }

  .image {
    flex: 1;
    margin: 20px;
    background: url(/images/side-login.png) center center no-repeat;
    background-size: cover;
  }
}