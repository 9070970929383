.create-registration-page {
  flex: 1;
  display: flex;
  flex-direction: column;

  .parts {
    display: flex;
    flex: 1;

    .list {
      flex: 1;

      .ctas {
        padding: 16px;
        display: flex;
        justify-content: flex-end;
        background: #F9F9F9;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        gap: 16px;

        .text {
          flex: 1;
          font-size: 1.2em;
        }
      }

      .empty {
        text-align: center;
        padding: 40px;
        color: grey;
        font-size: 1.3em;
      }

      .registrations {
        padding: 20px;

        &> * + * {
          margin-top: 20px;
        }

        .registration {
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 8px;

          .head {
            display: flex;
            margin-bottom: 20px;
            align-items: center;

            &.product {
              margin-bottom: 0px;
            }

            .name {
              flex: 1;
              font-size: 1.1em;
            }

            .remove {
              line-height: 0px;
              margin-left: 20px;
              cursor: pointer;

              &:hover {
                svg {
                  fill: red;
                }
              }
            }
          }
          
          .quantity {
            margin-right: 16px;
            display: flex;
            align-items: center;

            input {
              width: 60px !important;
              margin-left: 8px;
            }
          }

          .packages {

            &>* + * {
              margin-top: 5px;
            }
      
            .package {
              padding: 20px;
              border: 1px solid #ccc;
              border-radius: 8px;
              display: flex;
              align-items: center;
              cursor: pointer;
      
              &:hover {
                background: #E9E9E9;
              }
            }
          }

          &>.classes {
            margin-bottom: 20px;

            &.open {
              .cta {
                background: #E9E9E9;

                .icon {

                  svg {
                    transform: rotate(180deg);
                  }
                }
              } 
            }

            .cta {
              // cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 0px -20px;
              padding: 10px 20px;

              // &:hover {
              //   background: #E9E9E9;
              // }

              .icon {
                line-height: 0px;
                margin-right: 2px; // align with the remove icon


                svg {
                  width: 15px;
                  transition-duration: 0.4s;
                }
              }
            }

            .list {
              margin: 0px -20px;
              padding: 20px 20px;
              background: #E9E9E9;

              .no-classes {
                text-align: center;
                color: grey;
                padding: 20px 0px;
              }

              .classes {
    
                .classe {
                  margin-bottom: 10px;
                  padding: 20px;
                  border-radius: 8px;
                  border: 1px solid #ccc;
                  display: flex;
                  align-items: center;
                  background: white;
    
                  .name {
                    flex: 1;
                  }
                }
              }
            }
          }

          .no-option {
            text-align: center;
            color: grey;
          }

          .options {

            &> * + * {
              margin-top: 10px;
            }

            .option {
              padding: 20px;
              border-radius: 8px;
              border: 1px solid #ccc;
              cursor: pointer;
              display: flex;
              align-items: center;

              &.selected {
                border-color: #520b4e;
              }

              &.required {
                cursor: not-allowed;
              }

              .name {
                flex: 1;
              }
            }
          }
        }
      }
    }

    .infos {
      width: 450px;
      padding: 20px;
      border-left: 1px solid #eaeaea;

      .payer-message {
        font-size: 0.8em;
        margin-top: 5px;
        color: orange;
      }
    }
  }
}