.html-component {
  .editor {
    border-radius: 8px;
    border: 1px solid #ccc;

    .editorClassName {
      padding: 0px 20px;
    }

    .rdw-editor-toolbar.toolbarClassName {
      border-radius: 8px 8px 0px 0px;
    }

    .rdw-editor-toolbar {
      border-top: none;
      border-right: none;
      border-left: none;
    }

    .rdw-link-modal {
      height: auto;
    }
  }

  .label {
    display: flex;
    justify-content: space-between;

    .text {
      flex: 1;
    }

    .tabs {
      display: flex;
      padding-right: 20px;
  
      &> * + * {
        margin-left: 20px;
      }
  
      .tab {
        cursor: pointer;
        padding: 2px 5px;
        font-size: 0.9em;
  
        &.active {
          padding-bottom: 1px;
          margin-bottom: -1px;
          border-bottom: 2px solid blue;
          color: blue;
        }
      }
    }
  }
}