.profilePicture-component {
  cursor: pointer;
  position: relative;
  flex: 0 0 auto;
  border-radius: 45px;
  height: 90px;
  width: 90px;
  background: #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profilePicture-component:hover .upload {
  display: flex;
}
.profilePicture-component:hover .delete {
  display: flex;
}
.profilePicture-component .delete {
  display: none;
  position: absolute;
  top: -2px;
  right: -2px;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  background: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}
.profilePicture-component .delete:hover {
  background: #F9F9F9;
}
.profilePicture-component .delete svg {
  width: 15px;
  height: 15px;
  color: red;
}
.profilePicture-component img {
  object-fit: cover;
  height: 90px;
  width: 90px;
  border-radius: 45px;
}
.profilePicture-component .default {
  width: 80%;
  height: 80%;
  fill: white;
}
.profilePicture-component .upload {
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
}
.profilePicture-component .upload svg {
  width: 40px;
  height: 40px;
}