.dashboard-page {
  padding: 20px;

  h1 {
    margin-top: 0px;
  }

  .numbers {
    display: flex;
  }

  .split {
    display: flex;

    &> * + * {
      margin-left: 20px;
    }
  }

  .panel {
    flex: 1;

    h2 {
      margin-top: 0px;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 8px;
      margin-bottom: 0px;
    }

    .btn {
      border-top: 1px solid #D9D9D9;
      padding-top: 10px;
      width: 100%;
    }
  }
}

#graph-wrapper {

  .line {
    fill: url(#temperature-gradient);
    stroke: #fdd002;
    stroke-width: 2px;
  }
}


@media screen and (max-width: 650px) { 
  .dashboard-page {

    .numbers {
      flex-direction: column;
    }
  }

}