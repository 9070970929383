.image-component {
  cursor: pointer;
  position: relative;
  flex: 0 0 auto;
  border-radius: 8px;
  background: #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .upload {
      display: flex;
    }
    .delete {
      display: flex;
    }
  }

  .delete {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 100px;
    width: 25px;
    height: 25px;
    background: red;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 1px solid white;

    &:hover {
      background: rgb(201, 1, 1);
    }

    svg {
      fill: white;
      width: 15px;
      height: 15px;
    }
  }

  img {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
  }

  .default {
    width: 100%;
    height: 100%;
    fill: white;
    min-height: 300px;
  }

  .upload {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);

    svg {
      width: 40px;
      height: 40px;
      fill: black;
    }
  }
}