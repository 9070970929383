.component-notification {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999999;
  cursor: pointer;
  max-width: 350px;
  width: calc(100% - 40px);
}
.component-notification > * + * {
  margin-top: 20px;
}
.component-notification > .notification {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;
  border-radius: 6px;
  background: #ffcd46;
  cursor: pointer;
  display: flex;
  border-radius: 4px;
}
.component-notification > .notification .content {
  flex: 1;
  padding: 20px;
}
.component-notification > .notification .close {
  display: none;
  padding: 0px 20px;
  cursor: pointer;
  border-left: 1px solid #fff1cd;
}
.component-notification > .notification .close:hover {
  background: #e8aa00;
}
.component-notification > .notification:hover .close {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .component-notification {
    right: 10px;
    bottom: 10px;
    width: calc(100% - 20px);
    max-width: 500px;
  }
  .component-notification > * + * {
    margin-top: 10px;
  }
}