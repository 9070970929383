.guest-layout {
  display: flex;
  flex: 1;
  background: white;
}
.guest-layout .content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.guest-layout .content .wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.guest-layout .content footer {
  padding: 20px;
  text-align: center;
  color: grey;
  font-size: 0.9em;
}
.guest-layout .image {
  flex: 1;
  margin: 20px;
  background: url(/images/side-login.png) center center no-repeat;
  background-size: cover;
}