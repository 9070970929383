.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    text-align: center;

    .error {
      font-size: 5em;
      font-weight: bold;
    }

    .message {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 2em;
    }
  }
}