.component-notification {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999999;
  cursor: pointer;
  max-width: 350px;
  width: calc(100% - 40px);

  &> * + * {
    margin-top: 20px;
  }

  &>.notification {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 32px 0px;
    border-radius: 6px;
    background: #ffcd46;
    //color: white;
    cursor: pointer;
    display: flex;
    border-radius: 4px;

    .content {
      flex: 1;
      padding: 20px;
    }

    .close {
      display: none;
      padding: 0px 20px;
      cursor: pointer;
      border-left: 1px solid #fff1cd;

      &:hover {
        background: #e8aa00;
      }
    }

    &:hover {
      .close {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 650px) {

  .component-notification {
    right: 10px;
    bottom: 10px;
    width: calc(100% - 20px);
    max-width: 500px;

    &> * + * {
      margin-top: 10px;
    }
  }

}