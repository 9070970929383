*, *::before, *::after {
  box-sizing: border-box;
}

a, button {
  color: #007bff;
  text-decoration: none;
}
a.center, button.center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
a.ressource, button.ressource {
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: white;
  display: block;
  position: relative;
}
a.ressource:hover, button.ressource:hover {
  background: #E9E9E9;
}
a.ressource::after, button.ressource::after {
  content: ">";
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 1.4em;
  font-weight: 700;
  color: black;
}
a.btn, button.btn {
  color: white;
  font-weight: 500;
  font-size: 1em;
  cursor: pointer;
  background: none;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  outline: none;
  background: #520b4e;
  color: white;
}
a.btn:hover, button.btn:hover {
  background-color: #863781;
}
a.btn.center, button.btn.center {
  margin: 0px auto;
}
a.btn.icon, button.btn.icon {
  display: flex;
  align-items: center;
  gap: 8px;
}
a.btn.icon svg, button.btn.icon svg {
  margin: -3px 0px;
}
a.btn.normal, button.btn.normal {
  background: #F9F9F9;
  border: 1px solid #e9e9e9;
  color: black;
  font-weight: 600;
}
a.btn.normal:hover, button.btn.normal:hover {
  cursor: pointer;
  background: #F9F9F9;
}
a.btn.clear, button.btn.clear {
  background: white;
  font-weight: 500;
  padding: 5px;
}
a.btn.clear:hover, button.btn.clear:hover {
  background-color: #ceddff;
}
a.btn.grey, button.btn.grey {
  background: none;
  color: grey;
  font-size: 0.8em;
  padding: 5px 10px;
}
a.btn.grey:hover, button.btn.grey:hover {
  color: #007bff;
  background-color: #F9F9F9;
}
a.btn.light, button.btn.light {
  background: white;
  font-weight: 500;
  padding: 0px;
  color: blue;
}
a.btn.light:hover, button.btn.light:hover {
  color: #0b5ed7;
}
a.btn.red, button.btn.red {
  color: #df3e3e;
  background: #ffcece;
}
a.btn.small, button.btn.small {
  padding: 5px;
}
a.btn.full, button.btn.full {
  width: 100%;
}
a.btn:disabled, button.btn:disabled {
  color: grey;
  cursor: not-allowed;
  background: #D9D9D9;
}

.list-page .filters {
  margin: 20px;
  margin-bottom: 0px;
  display: flex;
}
.list-page .filters > * + * {
  margin-left: 20px;
}
.list-page .filters .filter.full {
  flex: 1;
}
.list-page .filters .sub-filters {
  margin: 0px;
}
.list-page .filters .sub-filters > * + * {
  margin-left: 20px;
}
.list-page .filters .sub-filters .filter.full {
  flex: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
body #root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.type-selector {
  padding: 40px 16px;
}
.type-selector .list {
  display: flex;
  gap: 8px;
  justify-content: center;
}
.type-selector .list .type {
  border: 1px solid #ccc;
  border-radius: 8px;
  aspect-ratio: 1;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  text-align: center;
  font-size: 1.2em;
  cursor: pointer;
}
.type-selector .list .type:hover {
  background-color: #eaeaea;
}
.type-selector .more {
  text-align: center;
  margin-top: 16px;
  color: grey;
}

.binding-selector {
  width: 100%;
}
.binding-selector .head {
  padding: 16px;
}
.binding-selector .products {
  display: flex;
  flex-direction: column;
}
.binding-selector .products > * + * {
  border-top: 1px solid #E9E9E9;
}
.binding-selector .products .product {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
}
.binding-selector .products .product img, .binding-selector .products .product .fake-img {
  background-color: #F9F9F9;
  flex: 0 0 auto;
  width: 70px;
  object-fit: cover;
  border-radius: 4px;
  height: 110px;
  border: none;
}
.binding-selector .products .product .infos {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.binding-selector .products .product .infos .title {
  font-weight: 700;
}
.binding-selector .products .product .infos .variants {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.binding-selector .products .product .infos .variants .variant {
  cursor: pointer;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 8px;
}
.binding-selector .products .product .infos .variants .variant:first-child {
  border-radius: 8px 8px 0px 0px;
}
.binding-selector .products .product .infos .variants .variant:last-child {
  border-radius: 0px 0px 8px 8px;
  border-bottom: 1px solid #ccc;
}
.binding-selector .products .product .infos .variants .variant:first-child:last-child {
  border-radius: 8px;
  border-bottom: 1px solid #ccc;
}
.binding-selector .products .product .infos .variants .variant:hover {
  background-color: #cacaca;
}
.binding-selector .products .product .infos .variants .variant .name {
  padding-bottom: 4px;
}
.binding-selector .products .product .infos .variants .variant .options {
  display: flex;
  gap: 8px;
  background: black;
}
.binding-selector .products .product .infos .variants .variant .options:nth-child(2) {
  border-radius: 4px 4px 0px 0px;
}
.binding-selector .products .product .infos .variants .variant .options:last-child {
  border-radius: 0px 0px 4px 4px;
}
.binding-selector .products .product .infos .variants .variant .options:nth-child(2):last-child {
  border-radius: 4px;
}
.binding-selector .products .product .infos .variants .variant .options .option {
  color: white;
  font-size: 0.8em;
  padding: 4px 12px;
}
.binding-selector .products .product .infos .variants.unified:hover .variant {
  background-color: #cacaca;
}
.binding-selector .products .product .infos .variants.unified .variant {
  padding-bottom: 0px;
}
.binding-selector .products .product .infos .variants.unified .variant:last-child {
  padding-bottom: 8px;
}
.binding-selector .products .product .infos .variants.unified .variant:nth-child(n+2) {
  border-top: none;
}