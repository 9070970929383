.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-found .content {
  text-align: center;
}
.not-found .content .error {
  font-size: 5em;
  font-weight: bold;
}
.not-found .content .message {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 2em;
}