.field-component {
  display: flex;
  margin-bottom: 20px;

  &>.label {
    width: 175px;
    font-weight: 500;
    flex: 0 0 auto;
    text-align: right;
    margin-right: 20px;
    font-weight: 700;
    
    &.box {
      margin-top: 10px;
    }
  }

  &>.value {
    flex: 1;
  }
}