.tabs-component {
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  border-bottom: 1px solid #eaeaea;
  padding-top: 5px;
  z-index: 999;

  &.sub {
    background: #F9F9F9;
  }

  &.no-padding {
    padding: 0px;
  }

  & > * + * {
    margin-left: 20px;
  }

  .tab-component {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;
    color: grey;
    white-space: nowrap;

    &:hover {
      color: #007bff;
    }

    &.active {
      position: relative;
      color: #007bff;
      
      &::after {
        content: ' ';
        position: absolute;
        background: #007bff;
        height: 3px;
        bottom: -2px;
        left: 0px;
        width: 100%;
        border-radius: 3px;
        //background: #007bff;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .tabs {
    max-width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    border: none;
  }
}