.file-component {
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  align-items: center;

  .preview {
    background: #E9E9E9;
    border-radius: 2px;
    margin-right: 10px;
    width: 60px;
    height: 70px;
    flex: 0 0 auto;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .infos {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: 'ellipsis';

    .filename {
      font-weight: 600;
    }

    .detail {
      font-size: 0.9em;
      color: grey;
    }
  }

  .actions {
    display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      margin-left: 10px;
  }
}