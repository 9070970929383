.alert-component {
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #b6d4fe;
  margin-bottom: 20px;
  color: #084298;
  background: #cfe2ff;

  &.red {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }
}