.user-layout {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.user-layout.is-test > .wrapper .corp .menu {
  max-height: calc(100vh - 51px);
}
.user-layout.is-test > .wrapper .corp .page {
  max-height: calc(100vh - 51px);
}
.user-layout .ressource-page {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.user-layout .ressource-page > .loader-component {
  padding: 20px 0px;
}
.user-layout .ressource-page .content {
  padding: 20px;
}
.user-layout .ressource-page .content.small {
  max-width: 770px;
}
.user-layout .ressource-page .content .field {
  display: flex;
  margin-bottom: 20px;
}
.user-layout .ressource-page .content .field.box .key {
  margin-top: 0px;
}
.user-layout .ressource-page .content .field .key {
  width: 200px;
  font-weight: bold;
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
}
.user-layout .ressource-page .content .field .value {
  flex: 1;
}
.user-layout .ressource-page .content .field .value > * + * {
  margin-top: 10px;
}
.user-layout .ressource-page .content .field .value .line {
  display: flex;
}
.user-layout .ressource-page .content .field .value .line > * + * {
  margin-left: 20px;
}
.user-layout .ressource-page .content .field .value .line > * {
  flex: 1;
}
.user-layout > .wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  transform: translate(0px, 0px);
  transition-duration: 500ms;
  background: white;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.3) 6px 6px 16px 0px;
  width: 100%;
}
.user-layout > .wrapper .corp {
  flex: 1;
  display: flex;
}
.user-layout > .wrapper .corp .menu {
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  width: 170px;
  border-right: 1px solid #e3e9ed;
  box-shadow: 0px 2px 0px #D9D9D9;
  z-index: 10;
  max-height: 100vh;
  overflow: auto;
  transition-duration: 300ms;
  background-color: #F9F9F9;
}
.user-layout > .wrapper .corp .menu .logo {
  margin-bottom: 16px;
  margin-top: 5px;
}
.user-layout > .wrapper .corp .menu .logo svg {
  color: #da4830;
}
.user-layout > .wrapper .corp .menu.mobile {
  display: none;
}
.user-layout > .wrapper .corp .menu .category {
  text-transform: uppercase;
  font-size: 0.6em;
  color: grey;
  font-weight: 700;
  padding: 10px 15px;
  margin-top: 16px;
}
.user-layout > .wrapper .corp .menu a {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.8em;
  margin: 0px 6px;
  padding: 10px 9px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 6px;
}
.user-layout > .wrapper .corp .menu a:hover {
  background-color: white;
}
.user-layout > .wrapper .corp .menu a.disabled {
  color: grey;
  cursor: not-allowed;
}
.user-layout > .wrapper .corp .menu a.sub-link {
  padding-left: 25px;
  font-weight: normal;
}
.user-layout > .wrapper .corp .menu a.active {
  background-color: white;
  border: 1px solid #e3e9ed;
  padding: 9px 8px;
}
.user-layout > .wrapper .corp .menu > .sub {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.user-layout > .wrapper .corp .menu > .sub .line {
  z-index: 2;
  position: absolute;
  height: 10px;
  top: 50%;
  margin-top: -6px;
  background: #dfe5eb;
  width: 100%;
}
.user-layout > .wrapper .corp .menu > .sub .text {
  z-index: 3;
  position: relative;
  background: white;
  color: #a4a6a9;
  font-size: 0.7em;
  margin: auto;
  padding: 8px 5px;
  text-transform: uppercase;
  margin-bottom: 3px;
}
.user-layout > .wrapper .corp .menu .separator {
  flex: 1;
  margin: 10px 0px;
}
.user-layout > .wrapper .corp .menu .account {
  z-index: 999;
  position: relative;
  border: none;
  border-top: 1px solid #eaeaea;
  font-size: 1em;
  background: none;
  text-align: left;
  cursor: pointer;
  font-size: 0.8em;
  border-radius: 0px;
  cursor: pointer;
  padding: 16px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #007bff;
}
.user-layout > .wrapper .corp .menu .account:hover {
  background-color: #F9F9F9;
}
.user-layout > .wrapper .corp .menu .account .sub {
  z-index: 999;
  position: absolute;
  left: 0px;
  bottom: 100%;
  background: white;
  padding: 4px 0px;
  border: 1px solid #ccc;
  width: 100%;
  color: black;
  border-radius: 4px;
  display: flex;
}
.user-layout > .wrapper .corp .menu .account .sub button {
  z-index: 999;
  position: relative;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  font-size: 1em;
  border-radius: 0px;
  cursor: pointer;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #007bff;
  width: 100%;
}
.user-layout > .wrapper .corp .menu .account .sub button:hover {
  background-color: #F9F9F9;
}
.user-layout > .wrapper .corp .page {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  max-height: 100vh;
  overflow: auto;
  transition-duration: 300ms;
}

@media screen and (max-width: 650px) {
  .user-layout .wrapper {
    flex-direction: column-reverse;
    max-height: 100vh;
    position: relative;
  }
}
@media print {
  body {
    min-height: none;
    max-height: none;
  }
  body .user-layout {
    min-height: none;
    max-height: none;
  }
  body .user-layout .wrapper {
    flex-direction: column-reverse;
    position: relative;
    min-height: none;
    max-height: none;
  }
  body .user-layout .wrapper .corp .menu {
    display: none;
  }
  body .user-layout .wrapper .corp .page {
    min-height: none;
    max-height: none;
  }
}