.profilePicture-component {
  cursor: pointer;
  position: relative;
  flex: 0 0 auto;
  border-radius: 45px;
  height: 90px;
  width: 90px;
  background: #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .upload {
      display: flex;
    }
    .delete {
      display: flex;
    }
  }

  .delete {
    display: none;
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    background: white;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 1px solid white;

    &:hover {
      background: #F9F9F9;
    }

    svg {
      width: 15px;
      height: 15px;
      color: red;
    }
  }

  img {
    object-fit: cover;
    height: 90px;
    width: 90px;
    border-radius: 45px;
  }

  .default {
    width: 80%;
    height: 80%;
    fill: white;
  }

  .upload {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);

    svg {
      width: 40px;
      height: 40px;
    }
  }
}