.checkbox-component {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.checkbox-component.ghost {
  cursor: inherit;
}
.checkbox-component.ghost:hover {
  background: none;
}
.checkbox-component.align-center {
  align-items: center;
}
.checkbox-component.no-label {
  width: fit-content;
}
.checkbox-component.no-label .input {
  margin: 0px;
}
.checkbox-component.inline {
  width: fit-content;
}
.checkbox-component.inline .input {
  margin: 0px;
}
.checkbox-component.disabled .input {
  background: #E9E9E9;
  border-color: #E9E9E9;
  cursor: not-allowed;
}
.checkbox-component.disabled .label {
  color: grey;
}
.checkbox-component.disabled:hover {
  background: none;
  cursor: not-allowed;
}
.checkbox-component.switchable .input {
  border-radius: 20px;
  width: 40px;
  position: relative;
  transition-duration: 0.1s;
}
.checkbox-component.switchable .input::after {
  content: " ";
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  background: #ccc;
  transition-duration: 0.1s;
}
.checkbox-component.switchable .input.checked {
  justify-content: flex-start;
}
.checkbox-component.switchable .input.checked svg {
  margin-left: 4px;
}
.checkbox-component.switchable .input.checked::after {
  left: 22px;
  background: green;
}
.checkbox-component:not(.inline):not(.ghost):hover {
  border-radius: 8px;
  background-color: #E9E9E9;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-left: -5px;
  padding: 5px 0px;
  padding-left: 5px;
}
.checkbox-component .label {
  margin-right: 10px;
  width: 100%;
}
.checkbox-component .input {
  width: auto;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  flex: 0 0 auto;
  margin: 0px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: white;
}
.checkbox-component .input.checked {
  border-color: #520b4e;
  background-color: #520b4e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-component .input.checked svg {
  width: 10px;
  height: 10px;
  fill: #a8ffa8;
}