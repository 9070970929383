.image-component {
  cursor: pointer;
  position: relative;
  flex: 0 0 auto;
  border-radius: 8px;
  background: #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-component:hover .upload {
  display: flex;
}
.image-component:hover .delete {
  display: flex;
}
.image-component .delete {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  background: red;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}
.image-component .delete:hover {
  background: #c90101;
}
.image-component .delete svg {
  fill: white;
  width: 15px;
  height: 15px;
}
.image-component img {
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
}
.image-component .default {
  width: 100%;
  height: 100%;
  fill: white;
  min-height: 300px;
}
.image-component .upload {
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
}
.image-component .upload svg {
  width: 40px;
  height: 40px;
  fill: black;
}