.create-registration-page {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.create-registration-page .parts {
  display: flex;
  flex: 1;
}
.create-registration-page .parts .list {
  flex: 1;
}
.create-registration-page .parts .list .ctas {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  background: #F9F9F9;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  gap: 16px;
}
.create-registration-page .parts .list .ctas .text {
  flex: 1;
  font-size: 1.2em;
}
.create-registration-page .parts .list .empty {
  text-align: center;
  padding: 40px;
  color: grey;
  font-size: 1.3em;
}
.create-registration-page .parts .list .registrations {
  padding: 20px;
}
.create-registration-page .parts .list .registrations > * + * {
  margin-top: 20px;
}
.create-registration-page .parts .list .registrations .registration {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.create-registration-page .parts .list .registrations .registration .head {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.create-registration-page .parts .list .registrations .registration .head.product {
  margin-bottom: 0px;
}
.create-registration-page .parts .list .registrations .registration .head .name {
  flex: 1;
  font-size: 1.1em;
}
.create-registration-page .parts .list .registrations .registration .head .remove {
  line-height: 0px;
  margin-left: 20px;
  cursor: pointer;
}
.create-registration-page .parts .list .registrations .registration .head .remove:hover svg {
  fill: red;
}
.create-registration-page .parts .list .registrations .registration .quantity {
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.create-registration-page .parts .list .registrations .registration .quantity input {
  width: 60px !important;
  margin-left: 8px;
}
.create-registration-page .parts .list .registrations .registration .packages > * + * {
  margin-top: 5px;
}
.create-registration-page .parts .list .registrations .registration .packages .package {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.create-registration-page .parts .list .registrations .registration .packages .package:hover {
  background: #E9E9E9;
}
.create-registration-page .parts .list .registrations .registration > .classes {
  margin-bottom: 20px;
}
.create-registration-page .parts .list .registrations .registration > .classes.open .cta {
  background: #E9E9E9;
}
.create-registration-page .parts .list .registrations .registration > .classes.open .cta .icon svg {
  transform: rotate(180deg);
}
.create-registration-page .parts .list .registrations .registration > .classes .cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px -20px;
  padding: 10px 20px;
}
.create-registration-page .parts .list .registrations .registration > .classes .cta .icon {
  line-height: 0px;
  margin-right: 2px;
}
.create-registration-page .parts .list .registrations .registration > .classes .cta .icon svg {
  width: 15px;
  transition-duration: 0.4s;
}
.create-registration-page .parts .list .registrations .registration > .classes .list {
  margin: 0px -20px;
  padding: 20px 20px;
  background: #E9E9E9;
}
.create-registration-page .parts .list .registrations .registration > .classes .list .no-classes {
  text-align: center;
  color: grey;
  padding: 20px 0px;
}
.create-registration-page .parts .list .registrations .registration > .classes .list .classes .classe {
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  background: white;
}
.create-registration-page .parts .list .registrations .registration > .classes .list .classes .classe .name {
  flex: 1;
}
.create-registration-page .parts .list .registrations .registration .no-option {
  text-align: center;
  color: grey;
}
.create-registration-page .parts .list .registrations .registration .options > * + * {
  margin-top: 10px;
}
.create-registration-page .parts .list .registrations .registration .options .option {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.create-registration-page .parts .list .registrations .registration .options .option.selected {
  border-color: #520b4e;
}
.create-registration-page .parts .list .registrations .registration .options .option.required {
  cursor: not-allowed;
}
.create-registration-page .parts .list .registrations .registration .options .option .name {
  flex: 1;
}
.create-registration-page .parts .infos {
  width: 450px;
  padding: 20px;
  border-left: 1px solid #eaeaea;
}
.create-registration-page .parts .infos .payer-message {
  font-size: 0.8em;
  margin-top: 5px;
  color: orange;
}